import { InviteUsersPayload } from './types'

export class KeycloakApi {
  private baseUrl: string
  private accessToken: string

  constructor (baseUrl: string) {
    this.baseUrl = baseUrl
  }

  private prepareRequest<T> (method: string, resource: string, body: T): Request {
    const url: URL = new URL(`${this.baseUrl}/${resource}`)
    const bodyJson = JSON.stringify(body)
    const request: Request = new Request(url, { method, body: bodyJson })
    request.headers.set('Authorization', `Bearer ${this.accessToken}`)
    request.headers.set('Content-Type', 'application/json')
    return request
  }

  public setAccessToken (token: string): void {
    this.accessToken = token
  }

  public async inviteUsers (payload: InviteUsersPayload): Promise<void> {
    const request = this.prepareRequest('POST', 'invite-users', payload)
    const result: Response = await fetch(request)
    if (!result.ok) {
      throw result
    }
  }
}

export const Keycloak = new KeycloakApi(window.configuration.OAUTH_AUTHORITY)
