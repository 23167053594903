import type { Component, AsyncComponentLoader } from 'vue'
import { defineAsyncComponent } from 'vue'

/**
 * Conditionally imports components based on environment.
 * In test environment, components are imported directly.
 * In other environments, components are lazy loaded.
 *
 * @param loader - Function that returns a promise resolving to a component
 * @param fallback - Optional fallback component to show while loading
 * @param options - Additional async component options
 * @returns Vue component
 */
export function lazyLoadComponent (
  loader: AsyncComponentLoader<Component>,
  fallback?: Component,
  options: {
    timeout?: number;
    errorComponent?: Component;
    loadingComponent?: Component;
    delay?: number;
    suspensible?: boolean;
  } = {},
) {
  const isTestEnv = import.meta.env.MODE.includes('test')

  if (isTestEnv) {
    return loader()
  }

  return defineAsyncComponent({
    loader,
    loadingComponent: options.loadingComponent || fallback,
    errorComponent: options.errorComponent,
    delay: options.delay ?? 0,
    timeout: options.timeout,
    suspensible: options.suspensible ?? true,
  })
}
