<script setup lang="ts">
import { PROPERTIES } from '@theme/properties'
import { computed } from 'vue'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()

const logoUrl = computed(() => {
  if (import.meta.env.VITE_APP_THEME === 'phoenix-contact' && userStore.isGatecUser) {
    return new URL('@theme/img/logo_gatec.svg', import.meta.url).href
  } else {
    return new URL('@theme/img/logo_white.png', import.meta.url).href
  }
})

let maxHeight: number|null = PROPERTIES.navigationLogoHeight
let maxWidth: number|null = null
if (PROPERTIES.navigationLogoFullWidth) {
  maxHeight = null
  maxWidth = 160
}
</script>

<template>
  <div
    class="pa-3 tw-relative tw-z-[2000]"
  >
    <router-link
      data-cy="home-logo"
      :to="{ name: 'home' }"
    >
      <v-img
        :max-height="maxHeight"
        :max-width="maxWidth"
        position="left"
        :src="logoUrl"
      />
    </router-link>
  </div>
</template>
