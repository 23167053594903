<i18n locale="de">
{
  "log_out": "Ausloggen"
}
</i18n>
<i18n locale="en">
{
  "log_out": "Log out"
}
</i18n>

<template>
  <v-progress-circular
    v-if="loading || !user"
    indeterminate
  />
  <v-menu
    v-else
    class="flat"
    location="top"
    width="184px"
    @update:model-value="$emit('user-menu:toggled', $event)"
  >
    <template #activator="{ props: menuProps, isActive }">
      <AskAedifionDialog
        v-if="showSupportItem"
      >
        <template #button="buttonProps">
          <NavigationItem
            v-if="showSupportItem"
            :icon="'fa:far fa-headset'"
            :title="t('links.meta.title.support')"
            v-bind="buttonProps"
          />
        </template>
      </AskAedifionDialog>

      <NavigationItem
        :class="{ 'user-menu__activator--active': isActive }"
        :icon="!user.avatar_url ? 'fa:fal fa-circle-user' : undefined"
        :title="user.fullNameOrEmail"
        v-bind="menuProps"
      >
        <template
          v-if="user.avatar_url"
          #avatar
        >
          <v-avatar
            class="user-menu__avatar"
            size="24px"
          >
            <v-img
              :alt="`${user.fullNameOrEmail} avatar`"
              lazy-src="/img/dummy-image.jpg"
              :src="user.avatar_url"
            />
          </v-avatar>
        </template>
      </NavigationItem>
    </template>

    <v-list
      class="py-0 rounded bg-neutral-darken4"
      density="compact"
    >
      <div class="user-menu__background rounded" />
      <NavigationItem
        class="user-menu__logout rounded"
        icon="fa:far fa-arrow-right-from-bracket"
        :title="t('log_out')"
        @click="$emit('user-menu:sign-out')"
      />
      <NavigationItemGroup
        :items="items"
      />
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import NavigationItem from './NavigationItem.vue'
import type { NavigationItemData } from './types'
import NavigationItemGroup from './NavigationItemGroup.vue'
import { useI18n } from 'vue-i18n'
import type { UserWithContext } from '@aedifion.io/aedifion-api'
import AskAedifionDialog from '@/components/AskAedifionDialog.vue'
import { useUserStore } from '@/stores/user'

export default defineComponent({
  name: 'NavigationUserMenu',

  components: {
    AskAedifionDialog,
    NavigationItem,
    NavigationItemGroup,
  },

  props: {
    loading: {
      default: false,
      required: false,
      type: Boolean as PropType<boolean>,
    },

    user: {
      required: false,
      type: Object as PropType<UserWithContext>,
    },
  },

  emits: ['user-menu:toggled', 'user-menu:sign-out'],

  setup () {
    const { t } = useI18n()
    return { t }
  },

  computed: {
    showSupportItem (): boolean {
      const userStore = useUserStore()
      return userStore.isGatecUser
    },

    items (): NavigationItemData[] {
      return [{
        icon: 'fa:far fa-cookie',
        routeNames: ['cookie-list'],
        title: this.t('links.meta.title.cookie_list'),
        to: { name: 'cookie-list' },
      }, {
        icon: 'fa:far fa-shield-check',
        routeNames: ['data-privacy'],
        title: this.t('links.meta.title.data_privacy'),
        to: { name: 'data-privacy' },
      }, {
        icon: 'fa:far fa-key',
        routeNames: ['user-security'],
        title: this.t('links.meta.title.user.security'),
        to: { name: 'user-security' },
      }, {
        icon: 'fa:far fa-address-card',
        routeNames: ['user-profile'],
        title: this.t('links.meta.title.user.profile'),
        to: { name: 'user-profile' },
      }]
    },
  },
})
</script>

<style lang="sass" scoped>
.user-menu__background
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  background-color: rgb(var(--v-theme-neutral-lighten3))
  opacity: .2

.user-menu__activator--active :deep(.item)
    z-index: 5

.user-menu__logout
  :deep(.item__background)
    background-color: rgb(var(--v-theme-error-darken2))
    opacity: .5
  :deep(.item:hover .item__background)
    opacity: 1
  :deep(.item__icon)
    color: rgb(var(--v-theme-neutral-lighten3)) !important

.user-menu__avatar
  margin-right: 8px !important

:deep(.v-overlay__content)
  left: 8px !important
  border: 0px none !important
  border-bottom-left-radius: 0px !important
  border-bottom-right-radius: 0px !important
  & .v-list
    padding-top: 0px

</style>
