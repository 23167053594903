/* eslint-disable no-console */
import { captureMessage, captureException } from '@sentry/vue'

export declare type SeverityLevel = 'fatal' | 'error' | 'warning' | 'info' | 'log' | 'debug'

export const shouldReportToSentry = () => globalThis.secrets?.SENTRY_DSN && !import.meta.env.MODE.startsWith('test-e2e')
export const shouldReportToConsole = () => !shouldReportToSentry() && import.meta.env.MODE !== 'test-unit'

const CONSOLE_REPORT_PREFIX = '(not reported to Sentry)\n'

function reportToConsoleWithAppropriateLevel (level: SeverityLevel, ...data: unknown[]): void {
  switch (level) {
    case 'fatal':
    case 'error':
      console.error(CONSOLE_REPORT_PREFIX, ...data)
      break
    case 'warning':
      console.warn(CONSOLE_REPORT_PREFIX, ...data)
      break
    case 'info':
      console.info(CONSOLE_REPORT_PREFIX, ...data)
      break
    case 'debug':
      console.debug(CONSOLE_REPORT_PREFIX, ...data)
      break
    case 'log':
    default:
      console.log(CONSOLE_REPORT_PREFIX, ...data)
      break
  }
}

export function reportMessage (message: string, level: SeverityLevel = 'error'): void {
  if (shouldReportToSentry()) {
    captureMessage(message, { level })
  }
  if (shouldReportToConsole()) {
    reportToConsoleWithAppropriateLevel(level, message)
  }
}

async function reportFailedResponse (response: Response, level: SeverityLevel) {
  // TODO make aedifion-api return the request too so that we can report more
  // details (HTTP method, request parameters, …)
  const responseDescriptor = `${response.status} ${response.statusText} ${response.url}

${await response.text()}`
  reportMessage(responseDescriptor, level)
}

export async function reportError (error: unknown, level: SeverityLevel = 'error') {
  if (error instanceof Response) {
    await reportFailedResponse(error, level)
  } else {
    if (shouldReportToSentry()) {
      captureException(error, { level })
    }
    if (shouldReportToConsole()) {
      reportToConsoleWithAppropriateLevel(level, error)
    }
  }
}

/**
 * Returns any kind of data as a string or `null` when the resulting string does
 * not give any information.
 * @param value The value to be converted into a string.
 * @param options `explicitNullish` (`boolean`, optional): whether nullish
 * values should be returned (`true`) or defaulted to `null` (`false`). Default:
 * `false`.
 * @returns `string`|`null`
 */
export function stringifyForLogging (value: unknown, options?: { explicitNullish: boolean }): string | null {
  const explicitNullish = options?.explicitNullish ?? false

  if (value === undefined || value === null) {
    return explicitNullish ? String(value) : null
  } else if (typeof value === 'object') {
    const stringified = JSON.stringify(value, null, 2)
    return stringified !== '{}' ? stringified : null
  }

  const stringified = value.toString()
  return stringified !== '' ? stringified : null
}
