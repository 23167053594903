<script setup lang="ts">
import { computed } from 'vue'

interface Props {
  disabled?: boolean,
  large?: boolean,
  modelValue?: string,
  placeholder?: string,
  showIcon?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  large: false,
  showIcon: true,
})

const emits = defineEmits<{
  (e: 'click:clear'): void,
  (e: 'update:modelValue', value: string): void
}>()

function clearInput () {
  emits('update:modelValue', '')
  emits('click:clear')
}

const value = computed({
  get: () => props.modelValue as string,
  set: (value: string) => emits('update:modelValue', value),
})
</script>

<template>
  <v-text-field
    v-model="value"
    :class="['search-field', { large }]"
    data-testid="search-input"
    :density="!large ? 'compact' : 'default'"
    :disabled="disabled"
    hide-details
    :placeholder="placeholder"
    variant="outlined"
    v-bind="$attrs"
    color="primary-darken2"
  >
    <template #append-inner>
      <div class="icons">
        <v-icon
          v-if="modelValue"
          class="clear-icon"
          color="neutral-darken3"
          data-testid="search-input-clear-button"
          :size="!large ? 'small' : 'default'"
          @click="clearInput"
        >
          {{ large ? 'fal' : 'far' }} fa-times-circle
        </v-icon>
        <v-icon
          v-if="showIcon"
          color="neutral-darken3"
          :size="!large ? 'x-small' : 'default'"
        >
          {{ large ? 'fal' : 'far' }} fa-search
        </v-icon>
      </div>
    </template>
  </v-text-field>
</template>

<style scoped lang="sass">
.search-field
  :deep(fieldset)
    border-width: 1px !important
    color: rgb(var(--v-theme-neutral-lighten1)) !important
  ::placeholder, ::-webkit-input-placeholder
    color: rgb(var(--v-theme-neutral-darken1)) !important
    opacity: 1
  &.v-input--is-focused :deep(fieldset)
    color: rgb(var(--v-theme-primary)) !important
  .icons
    display: flex
    .clear-icon
      margin-right: 8px
  &.large .icons
    margin-top: -1px
    .clear-icon
      margin-right: 12px
</style>
