import { Attribute, ComponentAttributesEditorState, ComponentInProjectAttributeWithError } from './types'
import { ComponentAttributeDefinition, ComponentInProjectWithContext } from '@aedifion.io/aedifion-api'
import i18n, { type AvailableLocales } from '@/i18n'
import { GetterTree } from 'vuex'
import { RootState } from '../types'
import { textForLocale } from '@/utils/helpers/locale'

export default {
  getAttributeDefinitionList: (state: ComponentAttributesEditorState): Attribute[] => {
    const attributeDefinitionList: ComponentAttributeDefinition[]|null = state.componentAttributeDefinitions
    const componentInProjectAttributes: ComponentInProjectAttributeWithError[] = state.componentInProject?.attributes ?? []

    if (!attributeDefinitionList) return []

    const result: Attribute[] = attributeDefinitionList.map((attributeDefinitionItem: ComponentAttributeDefinition) => {
      const matchingAttribute = componentInProjectAttributes?.find((attribute) => {
        return attributeDefinitionItem.alphanumeric_id === attribute.alphanumeric_id
      })
      return {
        ...attributeDefinitionItem,
        unit: matchingAttribute?.unit,
        default_value: formatValue(attributeDefinitionItem?.default_value),
        error: matchingAttribute?.error ?? false,
        tooltip_text: textForLocale(attributeDefinitionItem.description_de ?? '', attributeDefinitionItem.description_en ?? ''),
        value: matchingAttribute?.value,
        vue_component_type: getVueComponentType(attributeDefinitionItem),
      }
    })

    const locale = i18n.global.locale

    return sortAttributesBasedOnLocale(result, locale.value)
  },

  getComponentInProject: (state: ComponentAttributesEditorState): ComponentInProjectWithContext|null => state.componentInProject,

  isLoading: (state: ComponentAttributesEditorState): boolean => state.loading,

  isLoadingForFetch: (state: ComponentAttributesEditorState): boolean => state.loadingForFetch,
} as GetterTree<ComponentAttributesEditorState, RootState>

type ValueType = 'string' | 'float' | 'json' | 'link' | 'boolean'| 'enum'

const componentTypeMap = {
  float: 'float',
  json: 'json',
  string: 'string',
  link: 'link',
  boolean: 'boolean',
  enum: 'enum',
} as const

function getVueComponentType (attribute: ComponentAttributeDefinition) {
  if (attributeHasSelectiveCertification(attribute.alphanumeric_id!)) {
    return 'certificate'
  }

  if (isScheduledAttribute(attribute.alphanumeric_id!)) {
    return 'schedule'
  }

  return componentTypeMap[attribute.value_type as ValueType] || 'string'
}

function isScheduledAttribute (attributeAlphanumericId: string): boolean {
  return attributeAlphanumericId.endsWith('+SHD')
}

function attributeHasSelectiveCertification (attributeAlphanumericId: string): boolean {
  const listOfSelectiveCertifications: string[] = [
    'B+CERTS_DGNB',
    'B+CERTS_LEED',
    'B+CERTS_WSCORE',
  ]

  return listOfSelectiveCertifications.includes(attributeAlphanumericId)
}

function sortAttributesBasedOnLocale (attributes: Attribute[], locale: AvailableLocales): Attribute[] {
  if (locale === 'en') {
    return attributes.sort((a, b) => {
      return (a.nameEN ?? '').localeCompare(b.nameEN ?? '')
    })
  }

  return attributes.sort((a, b) => {
    return (a.nameDE ?? '').localeCompare(b.nameDE ?? '')
  })
}

function formatValue (value: string|undefined) {
  if (value === 'true' || value === 'false' || value === undefined) {
    return value
  }

  // The value could be a plain string
  if (!isNaN(parseFloat(value)) && typeof parseFloat(value) === 'number') {
    const locale = i18n.global.locale.value

    return Intl.NumberFormat(locale, {
      maximumFractionDigits: 6,
      minimumFractionDigits: 1,
      notation: 'standard',
    }).format(parseFloat(value))
  }

  return value
}
