import { addBreadcrumb } from '@sentry/vue'
import { notify } from '@kyvg/vue3-notification'

export function showErrorNotification (message: string): void {
  addBreadcrumb({
    category: 'notification',
    data: {
      notification: message,
    },
    level: 'error',
    message: 'Error notification shown to user',
    timestamp: Date.now(),
    type: 'info',
  })
  notify({
    duration: 6000,
    group: 'requests',
    text: message,
    type: 'error',
  })
}

export function showSuccessNotification (message: string): void {
  addBreadcrumb({
    category: 'notification',
    data: {
      notification: message,
    },
    level: 'debug',
    message: 'Success notification shown to user',
    timestamp: Date.now(),
    type: 'info',
  })
  notify({
    duration: 3000,
    group: 'requests',
    text: message,
    type: 'success',
  })
}

export function showWarningNotification (message: string): void {
  addBreadcrumb({
    category: 'notification',
    data: {
      notification: message,
    },
    level: 'warning',
    message: 'Warning notification shown to user',
    timestamp: Date.now(),
    type: 'info',
  })
  notify({
    duration: 3000,
    group: 'requests',
    text: message,
    type: 'warning',
  })
}

export function showInfoNotification (message: string, duration = 3000): void {
  addBreadcrumb({
    category: 'notification',
    data: {
      notification: message,
    },
    level: 'info',
    message: 'Info notification shown to user',
    timestamp: Date.now(),
    type: 'info',
  })
  notify({
    duration,
    group: 'requests',
    text: message,
    type: 'info',
  })
}
