<script setup lang="ts">
import { computed } from 'vue'
import { VUETIFY_COLORS } from '@theme/colors'

type Props = {
  backgroundColor?: string,
  figureColor?: string,
  borderColor?: string,
  icon?: boolean,
  small?: boolean,
  text?: string,
  tooltipText?: string,
  unit?: string,
  value: string,
  outlined?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
  figureColor: VUETIFY_COLORS.neutral.darken3,
  icon: false,
  small: false,
  borderColor: undefined,
})

// @ts-ignore
const figurePadding = computed(() => {
  return props.small ? '2px 6px' : '2px 8px'
})

const valueTextSize = computed(() => {
  return props.small ? 'tw-text-base' : 'tw-text-xl'
})
</script>

<template>
  <div data-testid="colored-figure">
    <v-tooltip
      :disabled="!props.tooltipText"
      location="top"
      content-class="aedifion-tooltip"
    >
      <template #activator="{props: tooltipProps}">
        <div
          :class="[ valueTextSize, 'figure tw-font-semibold tw-inline-flex tw-justify-center tw-h-[32px] text-no-wrap tw-w-fit tw-rounded', { 'tw-border': borderColor }, { 'tw-bg-white': !backgroundColor }, { 'tw-items-end': !props.icon }, { 'tw-items-center': props.icon }]"
          :style="{ backgroundColor: props.backgroundColor ? props.backgroundColor : undefined, color: props.figureColor, borderColor }"
          v-bind="tooltipProps"
        >
          {{ props.icon ? '' : props.value }}
          <v-icon
            v-if="props.icon"
            :color="props.figureColor"
            size="20"
          >
            {{ props.value }}
          </v-icon>
          <span
            v-if="!props.icon && props.unit"
            class="tw-text-sm tw-font-normal tw-mb-0.5 tw-ml-1"
          >{{ props.unit }}</span>
        </div>
      </template>
      <span
        v-if="props.tooltipText"
      >{{ props.tooltipText }}</span>
    </v-tooltip>
    <div
      v-if="text"
      data-test-id="colored-figure-text"
      class="text-body-1 text-neutral-darken2 mt-2"
    >
      {{ props.text }}
    </div>
    <slot />
  </div>
</template>

<style scoped lang="sass">
.figure
  padding: v-bind(figurePadding)
</style>
