<i18n locale="de">
{
  "title": "Cookie Manager",
  "description": "Diese Cookies ermöglichen es uns, Besuche zu zählen, damit wir die Leistung unseres Produkte messen und verbessern können. Sie unterstützen uns bei der Beantwortung der Fragen, welche Funktionen am beliebtesten sind, welche am wenigsten genutzt werden und wie sich Nutzer bewegen. Alle von diesen Cookies erfassten Informationen werden aggregiert und sind deshalb anonym.",
  "technical_cookie": {
    "title": "Technisch notwendige Cookies",
    "description": "Diese Cookies sind für das Funktionieren der Anwendung erforderlich und können nicht deaktiviert werden. Diese Cookies speichern keine persönlichen Daten."
  },
  "analytics_ux_cookie": {
    "title": "Cookies für Analysezwecke",
    "description": "Wir sammeln anonymisierte Daten, um die allgemeine Benutzererfahrung (UX) in unserer Anwendung zu analysieren. Diese anonymisierten Daten werden verwendet, um Verbesserungen innerhalb der Anwendung zu erreichen."
  },
  "cookie_support_message": "Wenn du uns gestattest, <strong>anonymisierte Daten</strong> zu Analysezwecken zu sammeln, werden wir dadurch Erkenntnisse gewinnen, die den Entwicklungsprozess und die Optimierung der Anwendung verbessern und eine bessere Nutzererfahrung ermöglichen. Wir würden uns freuen, wenn du uns bei diesem Prozess unterstützt, indem du uns das verarbeiten deiner <strong>anonymisierten Daten</strong> erlaubst.",
  "cookie_manager_reminder": "Sie können Ihre Cookie-Nutzung jederzeit in Ihren Profileinstellungen verwalten.",
  "cookie_opener": {
    "title": "Wir verwenden Cookies",
    "description": "Wir verwenden Cookies, um die Gesamtleistung und Nutzbarkeit der Anwendung zu verbessern.<br> Sie können Ihre Cookie-Nutzung jederzeit in Ihren Profileinstellungen verwalten.",
    "settings": "Einstellungen",
    "accept_all": "Alle akzeptieren",
    "privacy_policy": "Datenschutzerklärung",
    "cookie_list": "Liste der Cookies"
  }
}
</i18n>
<i18n locale="en">
{
  "title": "Cookie Manager",
  "description": "These cookies allow us to count visits so that we can measure and improve the performance of our product. They help us answer questions about which features are most popular, which are least used, and how users move around. All information collected by these cookies are aggregated and therefore anonymous.",
  "technical_cookie": {
    "title": "Required Cookies",
    "description": "These cookies are required for the application to function and cannot be disabled. Usually, these cookies are set only in response, such as setting your privacy preferences, logging in, or filling out forms. These cookies do not store any personal data."
  },
  "analytics_ux_cookie": {
    "title": "Cookies for analytics purposes",
    "description": "We are collecting anonymized data to analyze the overall user experience (UX) on our application. That anonymized data is being used to lead to improvements within the application."
  },
  "cookie_support_message": "Your permission to collect <strong>anonymized data</strong> for analytics purposes will help us improve the development process, optimize the application and create a better user experience. We would be grateful for your support in this process by giving us access to your <strong>anonymized data</strong>.",
  "cookie_manager_reminder": "You can manage your cookie-usage any time in your profile settings.",
  "cookie_opener": {
    "title": "We are using cookies",
    "description": "We are using cookies to improve the overall performance and usibility of the application.<br>You can manage your cookie-usage any time in your profile settings.",
    "settings": "Settings",
    "accept_all": "Accept All",
    "privacy_policy": "Privacy policy",
    "cookie_list": "List of cookies"
  }
}
</i18n>

<template>
  <!-- Settings dialog -->
  <v-dialog
    data-cy="cookie-manager"
    max-width="700px"
    persistent
    :model-value="shouldShowDialog"
    width="70%"
    v-bind="$attrs"
  >
    <v-card>
      <v-card-title class="text-h5 pa-4">
        {{ t('title') }}
      </v-card-title>
      <v-card-text class="text-subtitle-2 text-neutral-darken2">
        <p
          class="mb-4"
          v-text="t('description')"
        />
        <div class="d-flex justify-start align-center flex-row mb-4 mr-10">
          <div class="d-flex flex-column justify-center align-start pr-4">
            <span
              class="text-h6 text-neutral-darken3"
              v-text="t('technical_cookie.title')"
            />
            <p
              class="text-body-1 mb-2"
              v-text="t('technical_cookie.description')"
            />
          </div>
          <v-switch
            v-model="technicalCookieEnabled"
            class="aedifion-switch"
            density="compact"
            color="neutral"
            data-cy="technical-cookies-switch"
            disabled
            inset
          />
        </div>
        <hr>
        <div class="d-flex justify-start align-center flex-row mt-6 mb-4 mr-10">
          <div class="d-flex flex-column justify-center align-start pr-4">
            <span
              class="text-h6 text-neutral-darken3"
              v-text="t('analytics_ux_cookie.title')"
            />
            <p
              class="text-body-1 mb-2"
              v-text="t('analytics_ux_cookie.description')"
            />
          </div>
          <v-switch
            v-model="analyticsCookieEnabled"
            class="aedifion-switch"
            data-cy="analytics-cookies-switch"
            :disabled="isAedifionEmployee"
            inset
          />
        </div>
        <v-alert
          class="mb-4"
          color="success-darken2"
          variant="outlined"
        >
          <span v-html="t('cookie_support_message')" />
        </v-alert>
        {{ t('cookie_manager_reminder') }}
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="ml-auto"
          color="primary-darken2"
          variant="elevated"
          data-cy="cookie-manager-close"
          @click="closeDialog"
        >
          {{ t('actions.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Initial small dialog -->
  <v-dialog
    content-class="cookie-consent-opener__wrapper"
    data-cy="cookie-consent-opener"
    max-width="350px"
    :opacity="0"
    persistent
    :model-value="showCookieConsentOpener"
    v-bind="$attrs"
  >
    <v-card
      class="pa-2"
    >
      <v-card-title>
        {{ t('cookie_opener.title') }}
      </v-card-title>
      <v-card-text class="text-neutral-darken2">
        <span v-html="t('cookie_opener.description')" />
        <br><br>
        <router-link
          target="_blank"
          to="privacy"
        >
          <span class="text-primary-darken2">{{ t('cookie_opener.privacy_policy') }}</span>
        </router-link>
        |
        <router-link
          target="_blank"
          to="cookies"
        >
          <span class="text-primary-darken2">{{ t('cookie_opener.cookie_list') }}</span>
        </router-link>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="ml-auto"
          color="primary-darken2"
          data-cy="cookie-consent-opener-settings"
          variant="text"
          @click="openSettings"
        >
          {{ t('cookie_opener.settings') }}
        </v-btn>
        <v-btn
          color="primary-darken2"
          variant="elevated"
          data-cy="cookie-consent-opener-accept-all"
          :disabled="isAedifionEmployee"
          @click="acceptAllCookies"
        >
          {{ t('cookie_opener.accept_all') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { cookieconsent } from '@/cookieconsent'
import { mapState } from 'pinia'
import { storeCookieSelection } from '@/utils/helpers/cookieConsent'
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/stores/user'

export default defineComponent({
  name: 'CookieConsentDialog',

  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: false,
      required: false,
    },
  },

  emits: ['cookie-consent-dialog:close'],

  setup () {
    const { t } = useI18n()
    return { t }
  },

  data () {
    return {
      analyticsCookieEnabled: cookieconsent.allowedCategory('analytics_ux'),
      showCookieConsentOpener: !cookieconsent.validCookie('cc_cookie'),
      showDetailsDialog: false,
      technicalCookieEnabled: true,
    }
  },

  computed: {
    shouldShowDialog (): boolean {
      return this.showDetailsDialog || this.value
    },

    ...mapState(useUserStore, ['isAedifionEmployee']),
  },

  methods: {
    acceptAllCookies (): void {
      this.analyticsCookieEnabled = true
      this.showCookieConsentOpener = false
      this.confirmCookieSelection()
    },

    closeDialog (): void {
      this.confirmCookieSelection()
      this.showDetailsDialog = false
      this.$emit('cookie-consent-dialog:close')
    },

    confirmCookieSelection (): void {
      storeCookieSelection({
        analytics: this.analyticsCookieEnabled,
      })
    },

    openSettings (): void {
      this.showDetailsDialog = true
      this.showCookieConsentOpener = false
    },
  },
})
</script>

<style lang="sass">
  .cookie-consent-opener__wrapper
    position: fixed
    right: 0px
    bottom: 0px
</style>
