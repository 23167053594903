<script setup lang="ts">
import i18n from '@/i18n'
import { computed, ref } from 'vue'
import texts from '@theme/texts'
import { useI18n } from 'vue-i18n'

import { useUserStore } from '@/stores/user'

interface Props {
  message?: string,
  title?: string
}

withDefaults(defineProps<Props>(), {
  title: i18n.global.t('support_call_to_action.support') as string,
})

const { t } = useI18n()
const userStore = useUserStore()

const emailSupport = computed(() => {
  if (userStore.isGatecUser) {
    return texts.gatecEmailSupport ?? texts.emailSupport
  } else {
    return texts.emailSupport
  }
})

const phoneSupport = computed(() => {
  if (userStore.isGatecUser) {
    return texts.gatecPhoneSupport ?? texts.phoneSupport
  } else {
    return texts.phoneSupport
  }
})

const dialog = ref(false)
</script>

<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
  >
    <template #activator="{ props: dialogProps }">
      <slot
        v-if="emailSupport && phoneSupport"
        v-bind="dialogProps"
        name="button"
      >
        <v-btn
          color="primary"
          variant="outlined"
          v-bind="dialogProps"
        >
          {{ t('support_call_to_action.support') }}
        </v-btn>
      </slot>
    </template>
    <v-card>
      <v-card-title class="text-h5 pa-4">
        {{ title }}
      </v-card-title>
      <v-card-text class="text-body-2 text-neutral-darken1">
        <p
          v-if="message !== undefined"
          v-text="message"
        />
        <p
          class="my-4"
          v-text="t('contact_us')"
        />
        <div
          v-if="userStore.isGatecUser"
          class="tw-mb-1 tw-flex tw-flex-row tw-items-start tw-justify-start"
        >
          <v-icon
            class="ml-3 mr-2"
            size="small"
          >
            fa:far fa-building
          </v-icon>
          <div>
            <span>gatec AG</span><br>
            <span>Heimstrasse 16a</span><br>
            <span>8953 Dietikon</span><br>
          </div>
        </div>
        <div
          v-if="phoneSupport !== null"
          class="mb-1"
        >
          <v-icon
            class="ml-3 mr-2"
            size="small"
          >
            fa:far fa-phone
          </v-icon>
          <a
            class="text-decoration-none text-primary-darken2"
            :href="`tel:${phoneSupport}`"
            v-text="phoneSupport"
          />
        </div>
        <div v-if="emailSupport">
          <v-icon
            class="ml-3 mr-2"
            size="small"
          >
            fa:far fa-envelope
          </v-icon>
          <a
            class="text-decoration-none text-primary-darken2"
            :href="`mailto:${emailSupport}`"
            v-text="emailSupport"
          />
        </div>
        <br>
        <span v-text="t('happy_to_support')" />
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="ml-auto"
          color="primary-darken2"
          variant="text"
          @click="dialog = false"
        >
          {{ t('actions.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<i18n locale="de">
{
  "contact_us": "Kontaktiere uns einfach.",
  "happy_to_support": "Unser Team unterstützt und berät dich gerne!"
}
</i18n>
<i18n locale="en">
{
    "contact_us": "Just contact us.",
    "happy_to_support": "Our team will be happy to support and advise you!"
}
</i18n>
