import { AnalyticsResultsForComponentResultIds, AssignedPin, ComponentInProjectAttribute, ComponentInProjectWithContext } from '@aedifion.io/aedifion-api'
import { AssetOverviewState, Meter, MeterReadings } from './types'
import { DataRow, DataType } from '@/components/DataCard/types'
import { energyPriceAttributeId, readingsPinAlphanumericId } from './helpers'
import { getComponentAttribute } from '@/utils/helpers/componentAttribute'
import { GetterTree } from 'vuex'
import i18n from '@/i18n'
import { RootState } from '../types'
import { validateNotNullish } from '@/utils/helpers/validate'

function billingAttribute (meter: ComponentInProjectWithContext): ComponentInProjectAttribute|undefined {
  return meter.attributes?.find((attribute: ComponentInProjectAttribute) => {
    return attribute.alphanumeric_id === `${meter.component!.alphanumeric_id}+CALC_COST`
  })
}

function co2EmissionsAttribute (meter: ComponentInProjectWithContext): ComponentInProjectAttribute|undefined {
  return meter.attributes?.find((attribute: ComponentInProjectAttribute) => {
    return attribute.alphanumeric_id === `${meter.component!.alphanumeric_id}+CO2_EMIS_F`
  })
}

function energyPriceAttribute (meter: ComponentInProjectWithContext): ComponentInProjectAttribute|undefined {
  const attributeId: string|undefined = energyPriceAttributeId(meter.component!.alphanumeric_id!)
  return meter.attributes?.find((attribute: ComponentInProjectAttribute) => {
    return attribute.alphanumeric_id === attributeId
  })
}

function numberAttribute (meter: ComponentInProjectWithContext): ComponentInProjectAttribute|undefined {
  return meter.attributes?.find((attribute: ComponentInProjectAttribute) => {
    return attribute.alphanumeric_id === `${meter.component!.alphanumeric_id}+M_NUM`
  })
}

function readingsDataPointId (meter: ComponentInProjectWithContext): string|undefined {
  const pinId: string|undefined = readingsPinAlphanumericId(meter.component!.alphanumeric_id!)
  const pin: AssignedPin|undefined = meter.pins?.find((pin: AssignedPin) => {
    return pin.alphanumeric_id === pinId
  })
  return pin?.dataPointID
}

export default {
  isLightVersion: (_state: AssetOverviewState, _getters, _rootState, rootGetters): boolean => {
    const projectId = validateNotNullish(rootGetters['projects/currentProjectId'] as number|null)
    const technicalAvailabilityAnalysis: AnalyticsResultsForComponentResultIds|null = rootGetters['building_analyses/analysisOfProject'](projectId, 'technical_availability_analysis')
    if (technicalAvailabilityAnalysis) {
      return false
    }
    const wellbeingAnalysis: AnalyticsResultsForComponentResultIds|null = rootGetters['building_analyses/analysisOfProject'](projectId, 'wellbeing_analysis')
    if (wellbeingAnalysis) {
      return false
    }
    return true
  },

  isUserMeterAnalysisRunning: (state: AssetOverviewState): boolean => {
    return state.pendingAnalysisExecutions || state.pendingAnalogMeterAnalysisExecutions
  },

  numberOfDoneAnalysisExecutions: (state: AssetOverviewState): number => {
    return state.pendingAnalysisExecutionCount.done + state.pendingAnalogMeterExecutionCount.done
  },

  numberOfTotalAnalysisExecutions: (state: AssetOverviewState): number => {
    return state.pendingAnalysisExecutionCount.total + state.pendingAnalogMeterExecutionCount.total
  },

  prioritizedProfileData: (_state: AssetOverviewState, _getters, _rootState, rootGetters) => (count: number): DataRow[] => {
    type ValueGetter = (attribute: ComponentInProjectAttribute) => string
    const relevantAttributes: Readonly<Array<[string, DataType, ValueGetter, string?]>> = [
      ['B+TYP', DataType.String, attribute => i18n.global.t(`building_type.${attribute.value!}`) as string],
      ['B+BUILD_YEAR', DataType.Year, attribute => attribute.value!],
      ['B+NFA', DataType.Number, attribute => attribute.value!, 'm²'],
      ['B+CERTS_DGNB', DataType.String, attribute => i18n.global.t(`certification_grades.${attribute.value!}`) as string],
      ['B+CERTS_WSCORE', DataType.String, attribute => i18n.global.t(`certification_grades.${attribute.value!}`) as string],
      ['B+CERTS_BREEAM', DataType.String, attribute => attribute.value!],
      ['B+CERTS_LEED', DataType.String, attribute => i18n.global.t(`certification_grades.${attribute.value!}`) as string],
      ['B+CERTS_ECORE', DataType.Number, attribute => attribute.value!],
      ['B+CERTS_ESTAR', DataType.Number, attribute => attribute.value!],
      ['B+CERTS_GRESB', DataType.Number, attribute => attribute.value!],
      ['B+RENOV_YEAR', DataType.Year, attribute => attribute.value!],
      ['B_COUNT_PEO_MAX', DataType.Number, attribute => attribute.value!],
      ['B_COUNT_CAR_MAX', DataType.Number, attribute => attribute.value!],
    ]
    const result: DataRow[] = []
    const buildingComponent: ComponentInProjectWithContext|null = rootGetters['building_analyses/buildingComponentOfCurrentProject']
    for (let i = 0; i < relevantAttributes.length && result.length < count; i++) {
      const attribute: Readonly<ComponentInProjectAttribute|null> = getComponentAttribute(buildingComponent, relevantAttributes[i][0])
      if (attribute) {
        result.push({
          key: relevantAttributes[i][0],
          name: i18n.global.t(`attributes.${relevantAttributes[i][0]}`) as string,
          type: relevantAttributes[i][1],
          unit: relevantAttributes[i][3],
          value: relevantAttributes[i][2](attribute),
        })
      }
    }
    return result
  },

  readingsForSelectedMeter: (state: AssetOverviewState): MeterReadings & {
    meterId?: number;
  } => {
    const selectedMeterId: number|undefined = state.selectedUserMeter?.id
    if (selectedMeterId) {
      return {
        dataPointId: state.meterTimeseries[selectedMeterId]?.dataPointID,
        meterId: selectedMeterId,
        readings: state.meterTimeseries[selectedMeterId]?.data ?? [],
        unitLabelId: state.meterTimeseries[selectedMeterId]?.units,
      }
    } else {
      return {
        readings: [],
      }
    }
  },

  userMeterAnalysisIsAvailable: (state: AssetOverviewState): boolean => {
    const allMetersMonths: Array<Set<string>> = []
    for (const meterTimeseries of Object.values(state.meterTimeseries)) {
      const meterMonths: Set<string> = new Set()
      for (const reading of meterTimeseries?.data || []) {
        const time = new Date(reading.time)
        meterMonths.add(`${time.getFullYear()}-${time.getMonth() + 1}`)
      }
      allMetersMonths.push(meterMonths)
    }
    if (allMetersMonths.length === 0) {
      return false
    } else {
      // Get the intersection of all sets in allMetersMonth
      const commonMonths = allMetersMonths.reduce((previousValue, currentValue) => {
        return new Set([...previousValue].filter(month => currentValue.has(month)))
      })
      return commonMonths.size >= 2
    }
  },

  userMeters: (state: AssetOverviewState): Meter[] => {
    if (state.userMeters === null) return []
    return state.userMeters.reduce(function (userMeters, meter) {
      if (meter.component?.alphanumeric_id !== 'WSM') {
        const isAnalogMeter: boolean = meter.attributes?.find(attribute => attribute.key?.includes('DATA_SRC'))?.value === 'user'
        const co2Emissions: string|undefined = co2EmissionsAttribute(meter)?.value
        const energyPrice: string|undefined = energyPriceAttribute(meter)?.value
        const energyPriceUnit: string|undefined = energyPriceAttribute(meter)?.unit
        const meterAlphanumericId = meter.component?.alphanumeric_id
        const pinAlphanumericId = readingsPinAlphanumericId(meterAlphanumericId!)
        const datapointHashId: string|undefined = meter.pins?.find(pin => pin.alphanumeric_id === pinAlphanumericId)?.datapoint_hash_id
        userMeters.push({
          alphanumericId: meter.component?.alphanumeric_id,
          billing: billingAttribute(meter)?.value === 'True',
          co2Emissions: co2Emissions ? Number(co2Emissions) : undefined,
          componentName: meter.component?.nameEN,
          componentNameDe: meter.component?.nameDE,
          energyPrice: energyPrice ? Number(energyPrice) : undefined,
          energyPriceUnit,
          id: meter.id,
          meterName: meter.nameEN,
          number: numberAttribute(meter)?.value,
          pins: meter.pins,
          datapointHashId,
          projectId: meter.project_id!,
          readingsDataPointId: readingsDataPointId(meter),
          type: isAnalogMeter ? 'Analog' : 'Digital',
        },
        )
      }
      return userMeters
    }, [] as Meter[])
  },
} as GetterTree<AssetOverviewState, RootState>
