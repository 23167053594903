<template>
  <v-row
    v-if="!isLoading"
    id="card-block__row"
    v-resize="onProjectsResized"
    no-gutters
    style="gap:24px"
  >
    <v-col
      v-for="(project, index) in projects"
      :key="`project_card-${index}`"
      class="card-block__column"
      :style="colStyle"
    >
      <v-lazy
        class="tw-h-full"
        :options="{'threshold':0.1}"
        transition="fade-transition"
      >
        <ProjectCard
          data-cy="portfolio-project-card"
          :project="project"
        />
      </v-lazy>
    </v-col>
    <v-col
      v-for="(_card, index) in colsForEmptyCard"
      :key="index"
      :style="colStyle"
    />
  </v-row>
  <v-row
    v-else
    class="mx-1"
    justify="center"
    no-gutters
    style="gap:24px"
  >
    <v-col
      v-for="n in 8"
      :key="n"
      class="card-col"
    >
      <v-skeleton-loader
        elevation="0"
        height="416"
        type="card, list-item, list-item, table-heading"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { getEmptyColumnsCount } from '@/utils/helpers/sizing'
import ProjectCard from '@/views/Portfolio/ProjectCard.vue'
import { ProjectCardData } from '@/vuex/projects/types'
import { normalizeString } from '@/utils/helpers/strings'

export default defineComponent({
  name: 'CardsBlock',

  components: {
    ProjectCard,
  },

  props: {
    search: {
      default: '',
      required: false,
      type: String,
    },
  },

  data () {
    return {
      colsForEmptyCard: 0 as number,
    }
  },

  computed: {
    colStyle (): Record<string, string> {
      return {
        height: '402px',
        'min-width': this.$vuetify.display.lgAndUp ? '370px' : '346px',
      }
    },
    isLoading (): boolean {
      return this.$store.getters['projects/isLoading']
    },

    projects (): ProjectCardData[] {
      const projects: ProjectCardData[] = this.$store.getters['projects/projectCardsData']
      if (this.search === null || !(/\S/).test(this.search)) {
        return projects
      } else {
        return projects.filter((project: ProjectCardData) => {
          const normalizedProjectName = normalizeString(project.name) || ''
          const normalizedSearch = normalizeString(this.search) || ''
          return normalizedProjectName.includes(normalizedSearch)
        })
      }
    },
  },

  updated () {
    this.onProjectsResized()
  },

  methods: {
    onProjectsResized (): void {
      const rowElement: HTMLElement = document.querySelector('#card-block__row')! as HTMLElement
      const columnElement: HTMLElement|null = document.querySelector('.card-block__column')

      if (!columnElement) return

      const rowWidth: number = rowElement.clientWidth

      const columnWidth: number = this.projects.length === 1 ? 400 : columnElement.clientWidth

      this.colsForEmptyCard = getEmptyColumnsCount(rowWidth, columnWidth, this.projects.length)
    },
  },
})
</script>

<style lang="sass">
  .card-col
    min-width: 346px
    max-width: 531px
</style>
