/* eslint-disable no-empty */
import { DBSchema, IDBPDatabase, openDB } from 'idb'
import { isIDBAvailable } from '../../utils/helpers/idb'

const CURRENT_SCHEMA_VERSION = 1
const API_REQUESTS_CACHE_DATABASE = 'api-requests-cache'
const API_REQUESTS_CACHE_STORE = 'requests'
const DATE_COLUMN = 'date'
const RESPONSE_COLUMN = 'response'
const API_VERSION_COLUMN = 'apiVersion'

type SerializedResponse = {
  body?: BodyInit | null
  headers?: HeadersInit
  status?: number
  statusText?: string
}

type StoredResponse = {
  [DATE_COLUMN]: Date,
  [RESPONSE_COLUMN]: SerializedResponse,
  [API_VERSION_COLUMN]?: string
}

interface ApiRequestsCacheDb extends DBSchema {
  [API_REQUESTS_CACHE_STORE]: {
    key: string,
    value: StoredResponse
  }
}

let database: IDBPDatabase<ApiRequestsCacheDb> | undefined

/**
 * Use this function to open or retrieve (if it’s already open) the API request
 * caching IndexedDb database using a singleton.
 * @returns The current API request caching IndexedDb database.
 */
const getDatabase = async (): Promise<IDBPDatabase<ApiRequestsCacheDb> | undefined> => {
  const idbAvailable: boolean = await isIDBAvailable()
  if (!database && idbAvailable) {
    database = await openDB(API_REQUESTS_CACHE_DATABASE, CURRENT_SCHEMA_VERSION, {
      upgrade (db, oldSchemaVersion) {
        if (oldSchemaVersion < 1) {
          // this database has never been open before, we create the store
          db.createObjectStore(API_REQUESTS_CACHE_STORE)
        }
        if (oldSchemaVersion < 2) {
          // put your code to migrate from v1 to v2 here
        }
      },
    })
  }

  return database
}

/**
 * Transforms a request into an "identifier" string that describes its HTTP
 * method, route, and query parameters.
 * @param request The request that we want to get the ID from.
 * @returns The request identifier.
 */
const getRequestId = (request: { method: string, url: string }): string => {
  return `${request.method} ${request.url}`
}

/**
 * Serializes a Response into an object that can be handled by the structured
 * clone algorithm.
 * @param response The Response that should be serialized.
 * @returns The serialized Response.
 */
const serializeResponse = async (response: Response): Promise<SerializedResponse> => {
  return {
    body: await response.blob(),
    headers: Object.fromEntries(response.headers.entries()),
    status: response.status,
    statusText: response.statusText,
  }
}

/**
 * Deserializes a serialized Response object back into a regular Response typed
 * value.
 * @param serializedResponse The SerializedResponse that we want to transform
 * back into a Response.
 * @returns The original Response.
 */
const deserializeResponse = (serializedResponse: SerializedResponse): Response => {
  return new Response(serializedResponse.body, {
    headers: serializedResponse.headers,
    status: serializedResponse.status,
    statusText: serializedResponse.statusText,
  })
}

/**
 * Asks the cache for the response that was cached the most recently for this
 * request, and returns it if it exists. Optionnally an invalidation delay can
 * be specified. If the cached response is older than this value, it won't be
 * returned. Optionally an API version can be specified. If the cached response
 * has a lower API version, it will be deleted from the cache and won't be returned.
 * @param request The request that we should look for in the cache.
 * @param cacheInvalidationDelay The timespan after which a cached response is
 * considered obsolete (in milliseconds). Omit this parameter if a cached
 * response should be used regardless of how old it is.
 * @param apiVersion The API version that the cached response should be stored for.
 * If the cached response has a lower API version, it will be deleted from the cache.
 * If the cached response has no API version, it will be ignored.
 * @returns The most recent cached response for the request if there is one that
 * satisfies the constraints, `undefined` otherwise.
 */
export const retrieveCachedResponse = async (request: Request, cacheInvalidationDelay?: number, apiVersion?: string): Promise<Response | undefined> => {
  const db = await getDatabase()

  const requestId = getRequestId(request)
  const result: StoredResponse | undefined = await db?.get(API_REQUESTS_CACHE_STORE, requestId)
  if (result) {
    if (apiVersion) {
      if (!result[API_VERSION_COLUMN]) {
        return undefined
      } else if (result[API_VERSION_COLUMN] !== apiVersion) {
        await db?.delete(API_REQUESTS_CACHE_STORE, requestId)
        return undefined
      }
    }

    if (cacheInvalidationDelay === undefined) {
      return deserializeResponse(result[RESPONSE_COLUMN])
    }

    if (result[DATE_COLUMN].valueOf() >= (new Date()).valueOf() - cacheInvalidationDelay) {
      return deserializeResponse(result[RESPONSE_COLUMN])
    } else {
      await db?.delete(API_REQUESTS_CACHE_STORE, requestId)
    }
  }

  return undefined
}

/**
 * Adds a response to the cache for future use.
 * @param request The request that corresponds to the response we’re storing.
 * @param response The response that should be stored. It should be cloned if
 * you’re going to also return/use it, because a response can only be read once.
 */
export const storeResponseInCache = async (request: Request, response: Response, apiVersion?: string): Promise<void> => {
  const db = await getDatabase()

  const requestId = getRequestId(request)
  await db?.put(API_REQUESTS_CACHE_STORE, {
    [DATE_COLUMN]: new Date(),
    [RESPONSE_COLUMN]: await serializeResponse(response),
    [API_VERSION_COLUMN]: apiVersion,
  }, requestId)
}

/**
 * Invalidates the cache for a response by simply removing it from the database.
 * @param method The method of the request that should be removed, ie. 'GET', 'POST' etc.
 * @param url The full URL of the request that should be removed.
 */
export const removeResponseFromCache = async (method: string, url: string): Promise<void> => {
  try {
    const db = await getDatabase()
    const requestId = getRequestId({ method, url })
    await db?.delete(API_REQUESTS_CACHE_STORE, requestId)
  } catch (error) {}
}
